import React from "react"
import ReactMarkdown from "react-markdown"
import BackgroundImage from "gatsby-background-image";
import Card from "./contact-card"
import FormContact from "./contact-form"
import tikka from "../../content/assets/img/tikka-small.png"
import GaSection from "./gaSection"

function ContactUs(props) {
    let contacts = props.list;
    let contactLinks = props.info.list;

    return (
        <GaSection name="ContactUs">
        <section className="bot-contact">
            <div className="bot-hero-pic">
                <BackgroundImage className="bot-hero-pic" fluid={props.info.image.childImageSharp.fluid}/>
            </div>
            <div className="bot-hero">
                <div id="contact" className="bot-hero-tag"></div>
                <div className="bot-hero__in">
                    <h1 className=" bot-hero-title tac" dangerouslySetInnerHTML={{__html: props.info.title}}>
                        </h1>
                </div>
            </div>
            <div className="bot-section bg-gray">
                <div className="bot-section__in container-800">
                    <div className="bot-item">
                        <h3>{props.info.subtitle}</h3>
                        <p>{props.settings.companyname}, {props.settings.address}<br></br>
                            <a href={"mailto:"+ props.settings.email}>{props.settings.email}</a>
                        </p>
                    </div>
                    {
                        contactLinks.map((link, i)=>{
                            let contact = contacts.filter((c)=>c.fields.slug === "/"+link.contact+"/")[0];
                            // console.log(contact, link.contact);
                            return <Card key={i} info={contact.frontmatter} translation={props.translation}></Card>
                        })
                    }

                    <ReactMarkdown source={props.info.description} linkTarget="_blank"/>
                    
                    <FormContact translation={props.translation}></FormContact>
            
                    <div className="bot-tikka-section">
                        <img src={tikka} className="bot-tikka-animation" alt="tikka"></img>
                    </div>
                </div>

            </div>
        </section>
        </GaSection>

    )
}
export default ContactUs