import { Link } from "gatsby"
import React from "react"
import { MdKeyboardArrowRight } from "react-icons/md"
import ReactMarkdown from "react-markdown"
import { kebabCase } from "lodash";
import slugHandler from '../api/slugHandlerBrowser'
import GaSection from "./gaSection";
import BackgroundImage from "gatsby-background-image";

function Jobs(props) {
  let jobs = props.list;
  let jobLinks = props.info.list;

  return (
    <GaSection name="Jobs">
    <section className="bot-jobs posr">
      <div className="bot-hero-pic">
          <BackgroundImage className="bot-hero-pic" fluid={props.info.image.childImageSharp.fluid}/>
      </div>
      <div className="bot-hero">
        <div id="jobs" className="bot-hero-tag"></div>
        <div className="bot-hero__in">
          <h1 className=" bot-hero-title tac"  dangerouslySetInnerHTML={{__html: props.info.title}}>
          
            </h1>
        </div>
      </div>

      <div className="bot-section bg-gray">
        <div className="bot-section__in container-800">
          <div className="bot-item">
            <h3>{props.info.subtitle}</h3>
            <ReactMarkdown source={props.info.description} linkTarget="_blank"/>
          </div>
          <h3>{props.info.positionTitle}</h3>
          {
            jobLinks.map((link, i)=>{
              let job = jobs.filter((j)=>j.frontmatter.slug === `${link.position}` && j.frontmatter.enabled)[0];
              const path = job && slugHandler(
                job.frontmatter.language,
                job.frontmatter.slug
              )
              return job && (
              <div key={i} className="bot-item">
                <div id={kebabCase(job.frontmatter.slug)} className="bot-item-tag"></div>
                <h4 className="uc">{job.frontmatter.title}</h4>
                { job.frontmatter.shortdescription && <p>{job.frontmatter.shortdescription}</p>}
                <p>
                  <Link to={path} className="bot-small-title">{props.translation.jobPosition.readMore} <MdKeyboardArrowRight className="vam"></MdKeyboardArrowRight></Link>
                </p>
              </div>
              )

            })
          }

        </div>
      </div>
    </section>
    </GaSection>
  )
}
export default Jobs