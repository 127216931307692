import BackgroundImage from "gatsby-background-image"
import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import { MdKeyboardArrowRight } from "react-icons/md"

function Card(props){
    // console.log(props);
    const[isOpen, setIsOpen] = useState(false);
    let classes = "bot-card-item-content" + (isOpen ? " bot-card-item-content--open" : "");
    return(
        <div className="bot-card container-800 mb3">
            <div className="bot-card_in">

                <div className="bot-card-item">
                    <h4 className="uc">{props.info.name}</h4>
                    <div className={classes}>
                    <ReactMarkdown source={props.info.description} linkTarget="_blank"/>
                    </div>
                    <div className="mb1">
                        {
                           !isOpen && <button className="bot-small-title bot-text-link color-white" onClick={() => setIsOpen(true)}>{props.translation.jobPosition.readMore} <MdKeyboardArrowRight className="vam"></MdKeyboardArrowRight></button>

                        }
                    </div>
                    <div className="">
                        <a href={"mailto:"+props.info.email}>{props.info.email}</a>
                    </div>
                    
                    {
                        props.info.phone && <div className="">
                            <a href={"tel:"+props.info.phone}>{props.info.phone}</a>
                        </div>
                    }
                    {
                       props.info.linkedin && <div className="">
                        <a href={props.info.linkedin}>LinkedIn</a> 
                    </div>
                    }
                </div>
                <div className="bot-card-avatar mb2">
                    <BackgroundImage className="bot-card-pic" fluid={props.info.avatar.childImageSharp.fluid}></BackgroundImage>
                    {/* <div className="bot-card-pic" style={{backgroundImage: "url("+props.info.avatar.publicURL+")"}}></div> */}
                </div>
            </div>
        </div>

    )
}

export default Card