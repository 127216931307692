import React from "react"
import ReactMarkdown from "react-markdown"
import BackgroundImage from "gatsby-background-image";
import GaSection from "../components/gaSection"

function AboutUs(props) {

  return (
    <GaSection name="About us">
      <section className="posr">

        <div className="bot-hero-pic">
          <BackgroundImage className="bot-hero-pic" fluid={props.info.image.childImageSharp.fluid}>
          </BackgroundImage>
        </div>

        <div id="home" className="bot-hero">
          <div className="bot-hero__in">
            <h1 className="bot-hero-title bot-hero-title--1 tac" dangerouslySetInnerHTML={{__html: props.info.title}}>
              </h1>
          </div>
        </div>
        <div className="bot-section bg-gray">
          <div className="bot-section__in container-800">
            <div className="bot-item">
              <h3>{props.info.subtitle}</h3>
              <ReactMarkdown source={props.info.description} linkTarget="_blank" />
            </div>
          </div>
        </div>
      </section>
    </GaSection>
  )
}

export default AboutUs